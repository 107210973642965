.App {
  text-align: center;
  height: 100vh;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

/* underline on hover animation */
a,
.mid-text-link,
.homepage-link {
  display: inline-block;
  position: relative;
  text-decoration: none;
  color: inherit;
}

.mid-text-link:after {
  background-color: #383838;
  transform: scaleX(1);
  transform-origin: bottom left;
}

a:after,
.homepage-link:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -6px;
  left: 0;
  background-color: #6d6d6d;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.mid-text-link:hover:after {
  background-color: #6d6d6d;
}

a:hover:after,
.homepage-link:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.page {
  min-height: 100%;
  width: 100%;
  z-index: 0;
}

.page:not(#homepage) {
  margin-top: 80px;
  min-height: calc(100% - 80px);
}

.page:not(#about-me) {
  padding: 30px 60px;
}

@media only screen and (max-width: 710px) {
  .page:not(#about-me) {
    padding: 10px 4px;
  }
}
