#about-me {
  display: flex;
}

#about-me-media {
  object-fit: cover;
  max-width: 40%;
  padding-top: 40px;
}

#about-me-content {
  flex: 0 0 60%;
  padding: 20px;
  text-align: left;
}

#about-me h1 {
  margin-top: 20px;
}

#about-me h1,
#about-me-subheading,
.about-me-body {
  margin: 40px;
}

.about-me-body {
  line-height: 2;
}

@media only screen and (max-width: 710px) {
  #about-me {
    flex-direction: column-reverse;
  }

  #about-me-media {
    max-height: calc(60vh - 80px);
    max-width: 100%;
  }

  #about-me-content {
    padding: 4px;
  }
}
